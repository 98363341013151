body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label,
a,
p,
h1,
h2,
h3,
h4,
span,
input {
  font-family: 'Roboto';
}

.nice-dates-day.-highlight {
  color: green;
  font-weight: 600;
}
/* .nice-dates-day.-highlight::before {
  border: 1px solid green;
  opacity: 1;
  background-color: white;
} */

.nice-dates-day.-selected::before {
  background-color: #1976d2;
  font-weight: 600;
}

/* .nice-dates-day::before {
  background-color: #1976d2 !important;
} */